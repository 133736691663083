import AdminLoginButtonComponent from '@wix/thunderbolt-elements/src/components/AdminLoginButton/viewer/AdminLoginButton';


const AdminLoginButton = {
  component: AdminLoginButtonComponent
};


export const components = {
  ['AdminLoginButton']: AdminLoginButton
};

